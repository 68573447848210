
.rotate90 {
	transform: rotate(90deg) translate(-50%, -100%);
	transform-origin: top left;
}

.rotate270 {
	transform: rotate(270deg) translate(-50%, 0%);
	transform-origin: top left;
}

.rotate0 {

}

.rotate180 {
	transform: rotate(180deg)
}

.rotate {
	mix-blend-mode: hard-light;
	position: absolute;
	top: 3%;
}

.leftIcon {
	left: 3%;
}

.rightIcon {
	right: 3%;
}
