
@font-face {
  font-family: 'Kalam';
  src: local('Kalam'), url(../fonts/Kalam-Regular.ttf) format('truetype');
  /* other formats include: 'woff2', 'truetype, 'opentype', 'embedded-opentype', and 'svg' */
}

.flex1 {
	flex-basis: unset
}

.video-responsive {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}

.video-responsive iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}
