
.rotateFace90 {
	transform: rotate(90deg) translate(-50%, -150%);
	transform-origin: top left;
}

.rotateFace270 {
	transform: rotate(270deg) translate(-50%, 0%);
	transform-origin: top left;
}

.rotateFace0 {

}

.rotateFace180 {
	transform: rotate(180deg)
}
