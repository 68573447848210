
.search-box {
	font-family: Kalam, Regular, Arial, serif;
	font-weight: normal;
	font-size: 100px;
}

.search-box-small {
	font-family: Kalam, Regular, Arial, serif;
	font-weight: normal;
	font-size: 36px;
}

.pi-spin {
	display: none !important;
}
